import { API_URL } from "constant";

import { useNetwork } from "hooks";
import { useCallback, useState } from "react";

export const useUserVerification = () => {
  const [mobileLinkOnDevice, setMobileLinkOnDevice] = useState({
    loaded: true,
    data: [],
    error: false,
  });
  const {
    post,
    get,
    data: loginApiData,
    statusCode,
  } = useNetwork({ updateState: false });

  const { patch } = useNetwork();

  const fetchCodesForVerification = useCallback(
    (code: string, hostType?: string) => {
      setMobileLinkOnDevice((prev) => ({ ...prev, loaded: false }));
      get(`${API_URL.USER_LOGIN}/${code}`, hostType).then((resp) => {
        if (resp) {
          const { securityCodes } = resp.data ?? {};
          if (securityCodes) {
            setMobileLinkOnDevice((prev) => ({
              ...prev,
              loaded: true,
              data: securityCodes,
            }));
            return;
          }
          setMobileLinkOnDevice((prev) => ({
            ...prev,
            error: true,
            loaded: true,
          }));
          return;
        }
        setMobileLinkOnDevice((prev) => ({ ...prev, loaded: true }));
      });
    },
    [get, setMobileLinkOnDevice]
  );

  const selectOptionFromMobile = useCallback(
    async (payload: any, hostType?: string): Promise<boolean> => {
      const resp = await post(API_URL.USER_LOGIN, payload, hostType);
      if (resp) {
        const { status } = resp;
        if (status === "approved") {
          return true;
        }
        return false;
      }
      return false;
    },
    [post]
  );

  const handleAuthUser = async (key: string, authId: string) => {
    const status = key === "accept" ? "APPROVED" : "REJECTED";
    try {
      const response: any = await patch(
        `${API_URL.AUTH_APPROVAL}/${authId}`,
        { status: status },
        "auth"
      );

      if (response) {
        const { status } = response;
        if (status?.toLowerCase() === "approved") {
          return true;
        }
        return false;
      }
      return false;
    } catch (err: any) {
      return false;
    }
  };
  return {
    fetchCodesForVerification,
    selectOptionFromMobile,
    mobileLinkOnDevice,
    data: loginApiData,
    statusCode,
    handleAuthUser,
  };
};
