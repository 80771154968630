import { useCallback, useEffect, useMemo, useState } from "react";

import { LOGO } from "constant";
import { useUserVerification } from "./stores";

import { Error, InvalidCode, MobileApprove, Success } from "./components";
import { NotFoundError } from "./components/not-found";
import { Loader } from "@storybook/loader/loader";
import { useGetLoationDetails } from "hooks/location-details";

type ISteps = "config" | "success" | "error" | "invalid";

export const MobileVerification = () => {
  const {
    fetchCodesForVerification,
    selectOptionFromMobile,
    statusCode,
    handleAuthUser,
  } = useUserVerification();
  const [steps, setSteps] = useState<ISteps>("config");
  const [isLoading, setIsLoading] = useState(false);

  const { getGeoInfo } = useGetLoationDetails();

  const params = new URLSearchParams(window.location.search);
  const loginCode = params.get("loginCode");
  const biometricCode = params.get("biometricCode");
  const inviteUserCode = params.get("inviteUserCode");
  const verificationCode = params.get("verificationCode");
  const phone = params.get("phone");
  const countryCode = params.get("countryCode");
  const hostType = params.get("hostType");
  const authIds = useMemo(() => window.location.pathname.split("/")[2], []);

  useEffect(() => {
    if (loginCode !== null) {
      fetchCodesForVerification(loginCode);
      return;
    }
    if (verificationCode !== null) {
      fetchCodesForVerification(verificationCode, hostType ?? "");
    }
    // eslint-disable-next-line
  }, [loginCode, verificationCode, hostType]);

  const formatCountryCode = (code: string) => {
    // Trim whitespace from front and back
    let formatcode = code.trim();

    // Add + in front if not present
    if (!formatcode.startsWith("+")) {
      formatcode = "+" + formatcode;
    }

    return formatcode;
  };

  const handleClickOption = useCallback(
    async (key: number | string, cb?: any) => {
      setIsLoading(true);

      if (authIds) {
        const status = await handleAuthUser(key as string, authIds);

        if (status) {
          setSteps("success");
          setIsLoading(false);
          return;
        } else {
          setSteps("error");
        }
        setIsLoading(false);

        return;
      }
      const { ip: fetchingIpAddress = "", country } = await getGeoInfo();
      if (cb) {
        await cb();
      }
      const loginPayload = {
        type: "phoneCode",
        code: loginCode,
        choice: key,
        // securityCode: `${key}`,
      };
      const inviteUserPayload = {
        choice: key,
        type: "phoneCode",
        code: inviteUserCode,
        verificationType: "phoneVerification",
      };

      const verificationPayload = {
        type: "phoneCode",
        code: verificationCode,
        choice: key,
        verificationType: "phoneVerification",
      };
      const biometricPayload = {
        type: "phoneCode",
        code: biometricCode,
        choice: key,
        verificationType: "webAuthnRegistration",
      };
      const payload = loginCode
        ? loginPayload
        : inviteUserCode
        ? inviteUserPayload
        : verificationCode
        ? verificationPayload
        : biometricPayload;

      let formattedCountryCode;
      if (countryCode) {
        formattedCountryCode = formatCountryCode(countryCode);
      }

      const status = await selectOptionFromMobile(
        {
          ...payload,
          countryCode: formattedCountryCode,
          phone,
          ipAddress: fetchingIpAddress || undefined,
          userRegion: country?.country || undefined,
        },
        hostType ?? ""
      );

      setIsLoading(false);
      if (status) {
        setSteps("success");
        return;
      }
      setSteps("error");
      return;
    },
    [
      authIds,
      getGeoInfo,
      loginCode,
      inviteUserCode,
      verificationCode,
      biometricCode,
      countryCode,
      selectOptionFromMobile,
      phone,
      hostType,
      handleAuthUser,
    ]
  );

  const manageCode = useMemo((): string => {
    if (loginCode) {
      return "loginCode";
    }
    if (biometricCode) {
      return "biometricCode";
    }
    if (verificationCode) {
      return "verificationCode";
    }
    if (inviteUserCode) {
      return "inviteUserCode";
    }
    return "loginCode";
  }, [biometricCode, inviteUserCode, loginCode, verificationCode]);

  const renderScreens = useMemo(() => {
    switch (steps) {
      case "success":
        return <Success />;
      case "error":
        return <Error />;
      case "invalid":
        return <InvalidCode />;

      default:
        return (
          <MobileApprove
            handleSelectOption={handleClickOption}
            code={manageCode}
            hostType={hostType ?? ""}
            isLoading={isLoading}
          />
        );
    }
  }, [steps, handleClickOption, manageCode, hostType, isLoading]);

  if (statusCode === 400) {
    return <NotFoundError />;
  }

  return (
    <div className="mobile-verification">
      {loginCode ||
      biometricCode ||
      inviteUserCode ||
      verificationCode ||
      authIds ? (
        <>
          {renderScreens}
          <div className="mobile-verification__powered-by">
            <span className="mobile-verification__powered-by-text">
              Powered By
            </span>
            <img
              className="mobile-verification__powered-by-img"
              src={LOGO.LIGHT}
              alt="brand"
            />
          </div>
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};
